import usePrivateRoute from "../hooks/useAxiosPrivate";
import NewWorkbookItem from "../components/worksheets/NewWorkbookItem";
import Filters from "../components/worksheets/Filters";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import { Pagination, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(far, fas, fab);

const FreeFlashcards = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    grade: new URLSearchParams(window.location.search).get("grade"),
  });
  const [displayCount, setDisplayCount] = useState(0);
  const [sortMethod, setSortMethod] = useState("newest");
  const [isPremiumChecked, setIsPremiumChecked] = useState(false);
  const [isFreeChecked, setIsFreeChecked] = useState(false);

  const togglePremium = () => {
    setIsPremiumChecked(!isPremiumChecked);
    if (!isPremiumChecked) setIsFreeChecked(false);
  };

  const toggleFree = () => {
    setIsFreeChecked(!isFreeChecked);
    if (!isFreeChecked) setIsPremiumChecked(false);
  };

  const authRoute = usePrivateRoute();
  const navigate = useNavigate();

  const WORKSHEETS_PER_PAGE = 20;

  const resetPage = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    search();
  }, [currentPage, filters, sortMethod, isPremiumChecked, isFreeChecked]);

  function search() {
    setIsLoading(true);
    authRoute
      .post(
        `/publicflashcards/search?page=${currentPage}&search=${searchTerm}&premium=${isPremiumChecked}&free=${isFreeChecked}${generateURLPlus(
          filters
        )}${sortMethod && `&sort=${sortMethod}`}`
      )
      .then((data) => {
        setIsLoading(false);
        setSearchResults(data.data.res);
        setDisplayCount(data.data.count);
        setCount(Math.ceil(data.data.count / WORKSHEETS_PER_PAGE));
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const selectFilter = (selected, filter) => {
    console.log(selected, filter);
    resetPage();
    setFilters((prev) => {
      if (prev[filter] == selected) {
        return {
          ...prev,
          [filter]: null,
        };
      }
      return {
        ...prev,
        [filter]: selected,
      };
    });
  };

  const generateURLPlus = (filters) => {
    if (!filters) return "";
    let urlPlus = [];
    Object.keys(filters).forEach((el) => {
      if (filters[el]) {
        urlPlus += `&${el}=${filters[el]}`;
      }
    });
    return urlPlus;
  };

  return (
    <div
      className="d-flex flex-column user-page-content pages-flex flex-grow-1"
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="w-100 pb-0"
        style={{
          padding: "1rem 1.55rem",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h3
            style={{
              fontWeight: "bolder",
            }}
          >
            Flashcards for Kids
          </h3>
          <div
            className="input-group rounded"
            style={{
              width: "300px",
            }}
          >
            <input
              type="search"
              className="form-control rounded"
              placeholder="Search Flashcards"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  resetPage();
                  search();
                }
              }}
            />
            <span
              className="input-group-text border-0 clickable"
              id="search-addon"
              onClick={(e) => {
                resetPage();
                search();
              }}
            >
              <i className="fas fa-search"></i>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-15">
            <select
              className="form-select"
              name="type"
              value={filters.grade}
              onChange={(e) => selectFilter(e.target.value, "grade")}
            >
              <option value="">Select Grades</option>
              {Filters.gradeFilters.map((grade, index) => {
                return (
                  <option key={index} value={grade.search}>
                    {grade.text}
                  </option>
                );
              })}
            </select>
            <span className="vr"></span>
            <div className="d-flex gap-15">
              <button
                onClick={togglePremium}
                className="form-control d-flex flex-row justify-content-center align-items-center gap-2"
                style={{
                  backgroundColor: isPremiumChecked ? "blue" : "white",
                  color: isPremiumChecked ? "white" : "black",
                }}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-crown"
                  style={{
                    color: "gold",
                  }}
                />
                Premium
              </button>
              <button
                onClick={toggleFree}
                className="form-control"
                style={{
                  backgroundColor: isFreeChecked ? "blue" : "white",
                  color: isFreeChecked ? "white" : "black",
                }}
              >
                Free
              </button>
            </div>
          </div>
          <div className="d-flex text-nowrap gap-15 align-items-center">
            {isLoading ? 0 : displayCount} Results
            <select
              className="form-select"
              name="type"
              value={sortMethod}
              onChange={(e) => {
                resetPage();
                setSortMethod(e.target.value);
              }}
            >
              <option value="">Sort By</option>
              {Object.keys(Filters.sortMethods).map((sort) => {
                return (
                  <option
                    key={Filters.sortMethods[sort]}
                    value={Filters.sortMethods[sort]}
                  >
                    {sort}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="worksheetSection row pb-3 justify-content-center flex-grow-1">
        <div className="row">
          {isLoading ? (
            Array.from({ length: WORKSHEETS_PER_PAGE }, (_, index) => (
              <div
                key={index}
                className="flexColumn p-3 col-sm-6 col-md-3 workbook-item"
              >
                <div className="image-container">
                  <Skeleton
                    sx={{
                      transform: "none",
                      height: "100%",
                      position: "absolute",
                      width: "100%",
                      top: "0",
                      left: "0",
                    }}
                  />
                </div>
              </div>
            ))
          ) : searchResults.length ? (
            searchResults.map((workbook) => {
              return (
                <NewWorkbookItem
                  name={workbook.title}
                  key={workbook._id}
                  image={workbook.cover}
                  description={workbook.description}
                  premium={workbook.premium}
                  onClick={() =>
                    navigate(`${workbook.title.replace(/[\s\/]+/g, "-")}`)
                  }
                />
              );
            })
          ) : (
            <h4 className="d-flex justify-content-center align-items-center">
              Nothing to show here...
            </h4>
          )}
        </div>

        {count > 1 && !isLoading ? (
          <Pagination
            id="worksheets-pagination"
            className="d-flex justify-content-center pt-3"
            count={count}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
            page={currentPage}
            shape="rounded"
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FreeFlashcards;
