import { useEffect, useState } from "react";
import VideoCategory from "../components/videos/VideoCategory";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { Skeleton } from "@mui/material";

const VideosCategories = () => {
  const [playlists, setPlaylists] = useState(null);
  const authRoute = usePrivateRoute();

  useEffect(() => {
    authRoute
      .get("/publicplaylists")
      .then((response) => {
        setPlaylists(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      id="videos-page"
      style={{
        padding: "110px 0 0 0",
        minHeight: "100vh",
        display: "flex",
        paddingBottom: "25px",
      }}
    >
      <div className="videos-container flex-grow-1">
        {playlists
          ? playlists.map((playlist) => {
              return (
                <VideoCategory
                  key={playlist._id}
                  img={playlist.image}
                  name={playlist.name}
                />
              );
            })
          : Array.from({ length: 9 }, (_, index) => (
              <Skeleton
                key={index}
                className="my-container video-category wide-video"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "20px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                sx={{
                  transform: "none",
                }}
              />
            ))}
      </div>
    </div>
  );
};

export default VideosCategories;
