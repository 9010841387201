import { useEffect, useState } from "react";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import NewPDFView from "../components/worksheets/PDFView/PDFView";
import PDFUploadModal from "../components/worksheets/PDFUploadModal/PDFUploadModal";
import * as bootstrap from "bootstrap";

const UploadPdf = () => {
  const [newPDF, setNewPDF] = useState(null);
  const [editedPDF, setEditedPDF] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const showModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("exampleModal")
    );
    myModal.show();
  };

  const editPdfHandler = (pdf) => {
    setEditedPDF(pdf);
    showModal();
  };

  useEffect(() => {
    const myModalEl = document.getElementById("exampleModal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      setEditedPDF(null);
    });
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px 40px 0 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "15px",
          }}
        >
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#845adf",
              color: "white",
              fontWeight: 700,
            }}
            onClick={showModal}
          >
            Add New Worksheet
          </button>
          <div
            className="input-group rounded"
            style={{
              width: "300px",
            }}
          >
            <input
              type="search"
              className="form-control rounded"
              placeholder="Search Worksheets"
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchQuery(e.target.value);
                }
              }}
            />
            <span
              className="input-group-text border-0 clickable"
              id="search-addon"
              onClick={(e) => {
                setSearchQuery(searchInputValue);
              }}
            >
              <i className="fas fa-search"></i>
            </span>
          </div>
        </div>
        <PDFUploadModal editedPDF={editedPDF} setNewPDF={setNewPDF} />
        <NewPDFView
          editPdfHandler={editPdfHandler}
          newPDF={newPDF}
          searchQuery={searchQuery}
        />
      </div>
    </div>
  );
};

export default UploadPdf;
