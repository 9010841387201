import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentPage = () => {
  const [country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState();
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState();
  const [processingPayment, setProcessingPayment] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const authRoute = usePrivateRoute();
  const location = useLocation();

  useEffect(() => {
    const fetchClientCountry = async () => {
      try {
        const response = await fetch("https://ipwhois.app/json/");
        const countryCode = await response.json();
        setCountry(countryCode?.country_code);
      } catch (error) {
        console.error("Error fetching client's country:", error);
      }
    };

    fetchClientCountry();
  }, []);

  useEffect(() => {
    if (!location.state?.data || !Object.keys(location.state?.data).length) {
      console.log("No plan is choosed");
      return navigate("/membership");
    }

    setData(location.state.data || {});
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    countries.registerLocale(en);
    const langCountries = countries.getNames("en");
    const allCountries = Object.keys(langCountries).map((code) => {
      return {
        name: langCountries[code],
        code: code,
      };
    });

    setCountriesList(allCountries);
  }, []);

  const handleOptionClick = (option) => {
    setPaymentMethod(option);
  };

  const paymentOptions = [
    {
      title: "Credit Card",
      paymentForm: (
        <>
          <CardNumberElement />
          <div style={{ height: "15px" }}></div>
          <CardExpiryElement />
          <div style={{ height: "15px" }}></div>
          <CardCvcElement />
          <div style={{ height: "15px" }}></div>
        </>
      ),
    },
    {
      title: "PayPal",
      icon: <i className="fa-brands fa-paypal"></i>,
      paymentForm: <h5>Can't pay with this method yet... :(</h5>,
    },
    {
      title: "Google Pay",
      icon: <i className="fa-brands fa-google-pay"></i>,
      paymentForm: <h5>Can't pay with this method yet... :(</h5>,
    },
  ];

  const notify = (err) =>
    toast.error(err, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

  const createSubscription = async () => {
    if (paymentMethod == undefined) {
      return notify("No payment method selected!");
    }

    setProcessingPayment(true);
    try {
      const stripePaymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement(CardNumberElement),
        type: "card",
        billing_details: {
          name,
          address: {
            country,
          },
        },
      });
      if (stripePaymentMethod.error) {
        console.log(stripePaymentMethod.error.message);
        notify(stripePaymentMethod.error.message);
        setProcessingPayment(false);
        return;
      }

      try {
        const res = await authRoute.post(
          "/create-subscription",
          {
            paymentMethod: stripePaymentMethod.paymentMethod.id,
            priceId: data.plan.stripeId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const confirm = await stripe.confirmCardPayment(res.data.clientSecret);
        if (confirm.error) {
          console.log(confirm.error.message);
          notify(confirm.error.message);
          setProcessingPayment(false);
          return;
        }

        setProcessingPayment(false);
        setSuccess(true);
        navigate("/membership/pay/success");
      } catch (e) {
        console.log(e?.response?.data ?? e.message);
        notify(e?.response?.data ?? e.message);
        setProcessingPayment(false);
        return;
      }
    } catch (err) {
      console.log(err);
      notify("This payment method is not supported yet :(");
    } finally {
      setProcessingPayment(false);
    }
  };

  return (
    <div
      id="payment-page"
      style={{
        padding: "110px 0 0 0",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div className="d-flex flex-sm-row flex-column container">
        <div className="payment-column col-sm-4 col-12">
          <h5>Billing Information</h5>
          <div className="content">
            <div className="mb-3">
              <label htmlFor="coutnry-option-input">Country</label>
              <select
                id="country-option-input"
                className="form-select"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option hidden disabled value="">
                  Choose country...
                </option>
                {countriesList.length &&
                  countriesList.map((country) => (
                    <option
                      key={country.code}
                      data-countrycode={country.code}
                      data-tokens={`${country.code} ${country.name}`}
                      value={country.code}
                    >
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label htmlFor="full-name-input">Full Name</label>
              <input
                id="full-name-input"
                className="form-control"
                placeholder="Enter your name..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="payment-column col-sm-4 col-12">
          <h5>Payment Method</h5>
          <div className="content d-flex flex-column gap-3">
            <div className="payment-options">
              {paymentOptions.map((option, index) => (
                <div
                  key={index}
                  className={`form-check mb-2 rounded border p-3`}
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => handleOptionClick(index)}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      option.title === paymentOptions?.[paymentMethod]?.title
                    }
                    onChange={() => handleOptionClick(index)}
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className="form-check-label ms-2"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="me-2">{option.title}</span>
                    {option.icon && option.icon}
                  </label>
                </div>
              ))}
            </div>
            {paymentOptions?.[paymentMethod]?.paymentForm && (
              <div className="payment-info">
                {paymentOptions?.[paymentMethod]?.paymentForm}
              </div>
            )}
            <div className="info">
              <p>
                <i className="fa-solid fa-lock"></i>
                <span className="ms-1">
                  Secure payment with SSL Encryption.
                </span>
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Having trouble with your payment? Please{" "}
                <a className="clickable link" href="#">
                  click here
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <div className="payment-column col-sm-4 col-12">
          <h5>Order Details</h5>
          <div className="content d-flex flex-column gap-2">
            <h6>Club Baby Premium</h6>
            <ul
              className="d-flex flex-column gap-2"
              style={{
                listStyle: "none",
                margin: "0",
                padding: "0",
              }}
            >
              <li className="d-flex justify-content-between">
                <p>Number of users</p>
                <p>1</p>
              </li>
              <li className="d-flex justify-content-between">
                <p>
                  <span className="me-1">Monthly subscription</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </p>
                <p>{data?.plan?.price ?? 0} USD</p>
              </li>
              <li className="d-flex justify-content-between">
                <p>
                  <strong>Total</strong>
                </p>
                <p>
                  <strong>{data?.plan?.price ?? 0} USD</strong>
                </p>
              </li>
              <li className="d-flex justify-content-between link clickable">
                <p>
                  <span className="me-1">Do you have a promo code?</span>
                  <i className="fa-solid fa-chevron-down"></i>
                </p>
              </li>
            </ul>
            <button
              disabled={processingPayment}
              className="btn btn-primary mx-auto"
              onClick={createSubscription}
              style={{
                width: "75%",
              }}
            >
              Confirm
            </button>
            <p
              style={{
                fontSize: "14px",
                width: "80%",
              }}
              className="mx-auto text-center"
            >
              Your subscription will renew automatically every{" "}
              {data?.plan?.noun ?? "_"} as one payment of{" "}
              {data?.plan?.price ?? 0} USD. Cancel it anytime from your
              subscription settings.
              <br />
              By clicking "Confirm" you agree to the
              <br />
              <a
                onClick={() => {
                  navigate("/tos");
                }}
                className="clickable link"
              >
                Terms and Conditions
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"
      />
    </div>
  );
};

export default PaymentPage;
