import { Link } from "react-router-dom";
import { useModal } from "../../context/LoginModalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { encode_uri } from "../../scripts/helper";

const VideoCategory = (props) => {
  const { playGame, id, img, name, premPop, premium, user } = props;
  let link = playGame ? `../watch/${encode_uri(name, true)}` : encode_uri(name, true);
  const { showModal } = useModal();

  return (
    <Link
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20px",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      to={link}
      className="my-container video-category wide-video"
      onClick={(e) => {
        if (playGame) {
          if (!user) {
            e.preventDefault();
            showModal("SIGNUP");
          } else if (premium) {
            e.preventDefault();
            premPop();
          }
        }
      }}
    >
      <div className="front-div d-flex justify-content-center align-items-center">
        <div className="play-hover">
          {/* <img src="/userHome/newPlayButton.svg" alt="" /> */}
        </div>
        {playGame && premium && (
          <div className="premium-game">
            <FontAwesomeIcon icon="fa-solid fa-crown" className="crown" />
          </div>
        )}
        <img className="game-image w-100" src={props.img} alt="Front Image" />
      </div>
      <div className="hidden-div">
        <p className="fw-bold">{props.name}</p>
      </div>
    </Link>
  );
};

export default VideoCategory;
