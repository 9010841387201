import Filters from "../Filters";
import "./GameUploadModal.css";
import { useEffect, useRef, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";

const GameUploadModal = (props) => {
  const { editedGame, setNewGame } = props;
  const [type, setType] = useState("ADD");
  const [isUploading, setIsUploading] = useState(false);

  const [name, setName] = useState("");
  const [premium, setPremium] = useState(false);
  const [objective, setObjective] = useState("");
  const [topic, setTopic] = useState("");
  const [instructions, setInstructions] = useState("");
  const [menuContext, setMenuContext] = useState(Filters.gameTypes[0].value);
  const [gameCode, setGameCode] = useState("");
  const [gameVariables, setGameVariables] = useState("");
  const [level, setLevel] = useState(1);
  const [image, setImage] = useState(null);
  const imageInputRef = useRef(null);
  const [videoEmbed, setVideoEmbed] = useState("");

  const authRoute = usePrivateRoute();

  const createGameObject = () => {
    return {
      name,
      premium,
      objective,
      topic,
      instructions,
      menuContext,
      gameLogic: gameCode,
      gameVars: gameVariables,
      level,
      image,
      videoEmbed,
    };
  };

  const closeModal = () => {
    const modal = bootstrap.Modal.getInstance(
      document.getElementById("admin-game-modal")
    );
    modal.hide();
  };

  const config = {
    EDIT: {
      title: "EDIT GAME",
      cancelBtn: "Close",
      submitBtn: "Save",
      submitBtnSubmitting: "Saving...",
    },
    ADD: {
      title: "ADD NEW GAME",
      cancelBtn: "Clear",
      submitBtn: "Submit",
      submitBtnSubmitting: "Submitting...",
    },
  };

  const initEditedValues = () => {
    setGameCode(editedGame.gameLogic);
    setGameVariables(editedGame.gameVars);
    setInstructions(editedGame.instructions);
    setMenuContext(editedGame.menuContext);
    setName(editedGame.name);
    setObjective(editedGame.objective);
    setVideoEmbed(editedGame.videoEmbed);
    setImage(editedGame.image);
    setPremium(editedGame.premium);
    setTopic(editedGame.topic);
  };

  useEffect(() => {
    setType(editedGame ? "EDIT" : "ADD");
  }, [editedGame]);

  useEffect(() => {
    (({ EDIT: initEditedValues, ADD: clearFormData })[type] || (() => {}))();
  }, [type]);

  const handleGameUpdate = async () => {
    Swal.fire({
      title: "Are you sure you want to save your changes?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsUploading(true);
        authRoute
          .patch(`/game/${editedGame._id}`, {
            data: JSON.stringify(createGameObject()),
            headers: {
              "content-type": "application/json",
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Updated!",
              text: "The worksheet has been updated successfully.",
              icon: "success",
            });
            setNewGame({
              ...response.data,
              oldSubject: editedGame.menuContext,
            });
            setIsUploading(false);
            closeModal();
          })
          .catch((error) => {
            console.error("Error updating file:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
            setIsUploading(false);
          });
      }
    });
  };

  const handleGameUpload = async () => {
    Swal.fire({
      title: "Uploading!",
      html: "It might take some time...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setIsUploading(true);
    try {
      const response = await authRoute.post(`/game`, {
        data: JSON.stringify(createGameObject()),
        headers: {
          "content-type": "application/json",
        },
      });

      setNewGame(response.data);

      console.log("File uploaded successfully");
      Swal.fire({
        title: "Success!",
        text: "Worksheet uploaded successfully!",
        icon: "success",
        confirmButtonText: "Keep uploading",
      }).then((result) => {
        if (result.isConfirmed) {
          clearFormData();
        }
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire({
        title: "Error!",
        html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
    setIsUploading(false);
  };

  const clearFormData = () => {
    setName("");
    setPremium(false);
    setObjective("");
    setVideoEmbed("");
    setImage(null);
    imageInputRef.current.value = "";
    setTopic("");
    setInstructions("");
    setMenuContext(Filters.gameTypes[0].value);
    setGameCode("");
    setGameVariables("");
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const modalElement = document.getElementById("admin-game-modal");
    modalElement.addEventListener("hidden.bs.modal", clearFormData);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", clearFormData);
    };
  }, []);

  useEffect(() => {
    const myModal = new bootstrap.Modal(
      document.getElementById("admin-game-modal")
    );

    const closeButton = document.querySelector(".modal-close-button");
    closeButton.addEventListener("click", () => {
      myModal.hide();
    });
  }, []);

  const handleCancelButton = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("admin-game-modal")
    );

    type == "EDIT" ? myModal.hide() : clearFormData();
  };

  return (
    <div
      className="modal fade"
      id="admin-game-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="admin-game-modal-label"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-50"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="admin-game-modal-label">
              {config[type].title}
            </h5>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="btn-close btn-close-white modal-close-button"
              {...(isUploading ? { tabIndex: -1 } : {})}
            ></button>
          </div>
          <div className="modal-body pb-0">
            <form>
              <div className="form-floating mb-3">
                <input
                  id="game-name-input"
                  className="form-control"
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-name-input">Name</label>
              </div>
              <div
                className="mb-3 d-flex"
                style={{
                  gap: "20px",
                }}
              >
                <div className="w-50 d-grid">
                  <label htmlFor="game-premium-input">Is Premium</label>
                  <div
                    className={`btn ${premium ? "btn-success" : "btn-danger"}`}
                    onClick={(e) => setPremium(!premium)}
                  >
                    {premium ? "Premium" : "Not Premium"}
                  </div>
                </div>
                <div className="w-50">
                  <label htmlFor="game-option-context-input">
                    Menu Context
                  </label>
                  <select
                    id="game-option-context-input"
                    value={menuContext}
                    className="form-select"
                    onChange={(e) => setMenuContext(e.target.value)}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  >
                    {Filters.gameTypes.map((type, index) => (
                      <option key={index} value={type.value}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-50">
                  <label htmlFor="image-cover">Cover Image</label>
                  <input
                    className="form-control"
                    id="image-cover"
                    accept="image/*"
                    type="file"
                    ref={imageInputRef}
                    onChange={(e) => handleImageChange(e)}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  />
                </div>
              </div>
              <div className="form-floating mb-3">
                <input
                  id="game-objective-input"
                  className="form-control"
                  placeholder=""
                  value={objective}
                  onChange={(e) => setObjective(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-objective-input">Objective</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  id="game-topic-input"
                  className="form-control"
                  placeholder=""
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-topic-input">Topic</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  id="game-instructions-input"
                  className="form-control"
                  placeholder=""
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-instructions-input">Instructions</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  id="game-video-embed-input"
                  className="form-control"
                  placeholder=""
                  value={videoEmbed}
                  onChange={(e) => setVideoEmbed(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-video-embed-input">Instructions YouTube Video</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  id="game-code-input"
                  className="form-control"
                  placeholder=""
                  value={gameCode}
                  onChange={(e) => setGameCode(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-code-input">Game Code</label>
              </div>
              <div className="form-floating">
                <textarea
                  id="game-variables-input"
                  className="form-control"
                  placeholder=""
                  value={gameVariables}
                  onChange={(e) => setGameVariables(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-variables-input">Game Variables</label>
              </div>
            </form>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-outline-danger modal-cancel-button"
              id="modal-cancel-button"
              onClick={handleCancelButton}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {config[type].cancelBtn}
            </button>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#845adf",
                color: "white",
              }}
              onClick={editedGame ? handleGameUpdate : handleGameUpload}
              disabled={isUploading}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {isUploading
                ? config[type].submitBtnSubmitting
                : config[type].submitBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameUploadModal;
