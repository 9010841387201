import { useEffect, useState } from "react";
import "./signupContent.css";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { authRoute } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateEmail, validatePassword } from "../../../scripts/validations";
import jwt_decode from "jwt-decode";
import { useModal } from "../../../context/LoginModalContext";
const REGISTER_URL = "/register";

const SignupContent = () => {
  const { setModalType, closeModal } = useModal();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [age, setAge] = useState(new Date());
  const [grade, setGrade] = useState("1");
  const [type, setType] = useState("user");
  const [err, setErr] = useState("");

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToAdmin = (accessToken) => {
    const decodedToken = jwt_decode(accessToken);
    const adminTypes = ["Admin", "Super Admin", "Operator"];
    if (
      adminTypes.map((item) => item.toLowerCase()).includes(decodedToken.role)
    ) {
      navigate("/admin", { replace: true });
    }
  };

  const clearFormData = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setErr("");
  };

  useEffect(() => {
    const modalElement = document.getElementById("login-modal");
    modalElement.addEventListener("hidden.bs.modal", clearFormData);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", clearFormData);
    };
  }, []);

  const handleSignup = async (e) => {
    e.preventDefault();
    setErr("");
    if (!validateEmail(email)) {
      setErr("Please enter a valid email");
      return;
    }
    if (password !== confirmPassword) {
      setErr("Passwords don't match!");
      return;
    }
    if (!validatePassword(password)) {
      setErr(
        "Password must contain a capital letter and a digit, and contain 8 or more characters and less than 24 characters"
      );
      return;
    }
    try {
      const res = await authRoute.post(
        REGISTER_URL,
        JSON.stringify({
          email,
          password,
          name,
          age,
          grade,
          type,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const accessToken = res?.data?.accessToken;
      setAuth({ email, accessToken });
      setPassword("");
      closeModal();
      navigateToAdmin(accessToken);
    } catch (e) {
      console.log(e);
      if (!e?.response) {
        setErr("No server response");
      } else if (e.response?.status == 409) {
        setErr("User already taken");
      } else if (e.response?.status == 400) {
        setErr(e.response.data);
      } else {
        console.log(e.response.status);
        setErr("Unexpected error");
      }
    }
  };

  const signWithGoogle = async ({ email, name }) => {
    try {
      const res = await authRoute.post(
        "/register/google",
        JSON.stringify({
          email,
          password: "GOOGLE",
          name,
          age,
          grade,
          type,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const accessToken = res?.data?.accessToken;
      setAuth({ email, accessToken });
      setPassword("");
      closeModal();
      navigateToAdmin(accessToken);
    } catch (e) {
      console.log(e);
      if (!e?.response) {
        setErr("No server response");
      } else if (e.response?.status == 409) {
        setErr("User already taken");
      } else if (e.response?.status == 400) {
        setErr(e.response.data);
      } else {
        console.log(e.response.status);
        setErr("Unexpected error");
      }
    }
  };

  const googleLogin = async (cr) => {
    let theMail = jwtDecode(cr.credential).email;
    let allInfo = jwtDecode(cr.credential);
    try {
      const res = await authRoute.post(
        "/login/google",
        {
          token: theMail,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const accessToken = res?.data?.accessToken;
      setAuth({ theMail, accessToken });
      setPassword("");
      closeModal();
      navigateToAdmin(accessToken);
    } catch (e) {
      if (!e?.response) {
        setErr("No server response");
      } else if (e.response?.status == 403) {
        await signWithGoogle(allInfo);
      } else {
        setErr("Unexpected error");
      }
    }
  };

  return (
    <div>
      <h3
        className="login-modal-title my-3"
        style={{
          textAlign: "center",
        }}
      >
        Join Free to Club Baby Edu
      </h3>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className="mx-5 mb-3"
        onSubmit={handleSignup}
      >
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          className="inputText mb-3"
          placeholder="Full Name"
        />
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className="inputText mb-3"
          placeholder="Email"
        />
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          className="inputText mb-3"
          placeholder="Password"
        />
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          className="inputText"
          placeholder="Confirm Password"
        />
        <p
          style={{
            alignSelf: "center",
            color: "red",
            fontWeight: "600",
            marginTop: `${err ? "8px" : "0"}`,
          }}
        >
          {err}
        </p>
        <button
          className="btn btn-primary"
          style={{
            color: "black",
            fontSize: "20px",
            fontFamily: "Bubblegum Sans",
            fontWeight: "normal",
            color: "white",
            marginTop: `${err ? "8px" : "16px"}`,
          }}
          type="submit"
        >
          Join for Free
        </button>
      </form>
      <div
        id="sso-container"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <GoogleLogin
          onSuccess={(cr) => {
            googleLogin(cr);
          }}
          onError={() => console.log("Login Failed")}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        Already have an account?
        <div
          className="clickable"
          style={{
            color: "black",
            textDecoration: "underline",
            marginLeft: "5px",
            fontWeight: "bold",
          }}
          onClick={() => {
            setModalType("LOGIN");
          }}
        >
          Log In
        </div>
      </div>
      <p
        className="mx-5 mb-3"
        style={{
          color: "#A5A5A5",
          fontSize: "12px",
          textAlign: "justify",
        }}
      >
        By creating an account, you are agreeing to Club Baby Edu Terms and
        Conditions, confirming that you have read our Privacy Policy and to
        receiving helpful educational materials, updates and support by email.
        This site is protected by reCAPTCHA and the Google Privacy Policy and
        Terms of Service apply.
      </p>
    </div>
  );
};

export default SignupContent;
