import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import TOS from "./pages/TOS";
import RequireAuth from "./components/Auth/RequireAuth";
import RequireUnauth from "./components/Auth/RequireUnauth";
import { AuthProvider } from "./context/AuthProvider";
import PersistLogin from "./components/PersistLogin";
import GamesCategories from "./pages/GamesCategories";
import Membership from "./pages/Membership";
import RequireRoles from "./components/Auth/RequireRoles";
import Admin from "./pages/Admin";
import GameDisplay from "./pages/GameDisplay";
import PlayGame from "./pages/PlayGame";
import User from "./pages/User";
import UsersList from "./pages/UsersList";
import AdminsList from "./pages/AdminsList";
import AdminGames from "./pages/AdminGames";
import AdminVideos from "./pages/AdminVideos";
import PaymentPage from "./pages/PaymentPage";
import PaymentPageSuccess from "./pages/PaymentPageSuccess";
import AdminPaymentHistory from "./pages/AdminPaymentHistory";
import ResetPassword from "./pages/ResetPassword";
import PasswordResetPage from "./pages/PasswordResetPage";
import UploadPdf from "./pages/UploadPdf";
import NotFound from "./pages/NotFound";
import History from "./pages/History";
import AddUser from "./pages/AddUser";
import AddAdmin from "./pages/AddAdmin";
import LoginModal from "./components/loginModal/loginModal";
import FreeWorkbooks from "./pages/FreeWorkbooks";
import SelectedWorksheet from "./pages/SelectedWorksheet";
import AdminTestimonials from "./pages/AdminTestimonials";
import { ModalProvider } from "./context/LoginModalContext";
import { UserImageProvider } from "./context/UserImageContext";
import Page from "./pages/Page";
import AdminDevGame from "./pages/AdminDevGame";
import UploadFlashcard from "./pages/UploadFlashcard";
import FreeFlashcards from "./pages/FreeFlashcards";
import SelectedFlashcard from "./pages/SelectedFlashcard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import VideosCategories from "./pages/VideosCategories";
import VideoDisplay from "./pages/VideoDisplay";
import PlayVideo from "./pages/PlayVideo";
import { useEffect, useState } from "react";
const stripePromise = loadStripe(
  // "pk_test_51Nj1prHBVpkHO6qhTuxI1N2C70aFbIf5AQIWEp632p1ZsqGUhwWLczKG1Ufq2ORIaykGSwlcUZll135Bjo4esVlf00z7szab3n"
  "pk_live_51Nj1prHBVpkHO6qhoj5uyTs0eHkcLOzUXtoUX3xNQ3bJI6eyMjAvHBM0JuRE9DGimfPY23kk4r3Ci2KSXjno5BK500noZVOpra"
);

function App() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <ModalProvider>
            <UserImageProvider>
              <Header />
              <LoginModal />
              <div className="pages">
                <button
                  id="scrollToTopBtn"
                  onClick={scrollToTop}
                  className={isVisible ? "show" : "hide"}
                  title="Scroll to Top"
                >
                  <i className="fas fa-chevron-up"></i>
                </button>
                <Routes>
                  <Route element={<PersistLogin />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/worksheets">
                      <Route
                        path=""
                        element={
                          <Page title="Worksheets">
                            <FreeWorkbooks />
                          </Page>
                        }
                      />
                      <Route
                        path=":worksheetName"
                        element={
                          <Page title="Worksheets">
                            <SelectedWorksheet />
                          </Page>
                        }
                      />
                    </Route>
                    <Route path="/flashcards">
                      <Route
                        path=""
                        element={
                          <Page title="Flashcards">
                            <FreeFlashcards />
                          </Page>
                        }
                      />
                      <Route
                        path=":worksheetName"
                        element={
                          <Page title="Flashcards">
                            <SelectedFlashcard />
                          </Page>
                        }
                      />
                    </Route>
                    <Route path="/games">
                      <Route
                        path=""
                        element={
                          <Page title="Games">
                            <GamesCategories />
                          </Page>
                        }
                      />
                      <Route
                        path=":gameType"
                        element={
                          <Page title="Games">
                            <GameDisplay />
                          </Page>
                        }
                      />
                      <Route
                        path="play/:gameId"
                        element={
                          <Page title="Games">
                            <PlayGame />
                          </Page>
                        }
                      />
                    </Route>
                    <Route path="/videos">
                      <Route
                        path=""
                        element={
                          <Page title="Videos">
                            <VideosCategories />
                          </Page>
                        }
                      />
                      <Route
                        path=":playlist"
                        element={
                          <Page title="Videos">
                            <VideoDisplay />
                          </Page>
                        }
                      />
                      <Route
                        path="watch/:videoId"
                        element={
                          <Page title="Videos">
                            <PlayVideo />
                          </Page>
                        }
                      />
                    </Route>
                    <Route element={<RequireUnauth />}>
                      <Route path="/reset-pass" element={<ResetPassword />} />
                      <Route
                        path="/passwordReset"
                        element={<PasswordResetPage />}
                      />
                    </Route>
                    <Route path="/tos" element={<TOS />} />
                    <Route path="/membership" element={<Membership />} />
                    <Route element={<RequireAuth />}>
                      <Route element={<RequireRoles />}>
                        <Route
                          path="/membership/pay"
                          element={
                            <Elements stripe={stripePromise}>
                              <PaymentPage />
                            </Elements>
                          }
                        />
                        <Route
                          path="/membership/pay/success"
                          element={<PaymentPageSuccess />}
                        />

                        <Route
                          path="/profile"
                          element={
                            <Page title="Profile">
                              <User />
                            </Page>
                          }
                        />
                      </Route>
                      <Route element={<RequireRoles />}>
                        <Route path="/admin">
                          <Route
                            path=""
                            element={
                              <Page title="Admin | Dashboard">
                                <Admin />
                              </Page>
                            }
                          />
                          <Route
                            path="users"
                            element={
                              <Page title="Admin | Users">
                                <UsersList />
                              </Page>
                            }
                          />
                          <Route
                            path="admins"
                            element={
                              <Page title="Admin | Admins">
                                <AdminsList />
                              </Page>
                            }
                          />
                          <Route path="games">
                            <Route
                              path=""
                              element={
                                <Page title="Admin | Games">
                                  <AdminGames />
                                </Page>
                              }
                            />
                            <Route
                              path="dev"
                              element={
                                <Page title="Admin | Game Dev">
                                  <AdminDevGame />
                                </Page>
                              }
                            />
                          </Route>
                          <Route
                            path="videos"
                            element={
                              <Page title="Admin | Videos">
                                <AdminVideos />
                              </Page>
                            }
                          />
                          <Route
                            path="worksheets"
                            element={
                              <Page title="Admin | Worksheets">
                                <UploadPdf />
                              </Page>
                            }
                          />
                          <Route
                            path="flashcards"
                            element={
                              <Page title="Admin | Flashcards">
                                <UploadFlashcard />
                              </Page>
                            }
                          />
                          <Route
                            path="payment-history"
                            element={
                              <Page title="Admin | Payment History">
                                <AdminPaymentHistory />
                              </Page>
                            }
                          />
                          <Route
                            path="history"
                            element={
                              <Page title="Admin | History">
                                <History />
                              </Page>
                            }
                          />
                          <Route
                            path="admin-create"
                            element={
                              <Page title="Admin | Create Admin">
                                <AddAdmin />
                              </Page>
                            }
                          />
                          <Route
                            path="user-create"
                            element={
                              <Page title="Admin | Create User">
                                <AddUser />
                              </Page>
                            }
                          />
                          <Route
                            path="testimonials"
                            element={
                              <Page title="Admin | Testimonials">
                                <AdminTestimonials />
                              </Page>
                            }
                          />
                          <Route
                            path="*"
                            element={
                              <Page title="Admin | Not Found">
                                <NotFound admin={true} />
                              </Page>
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Routes>
              </div>
              <Footer />
            </UserImageProvider>
          </ModalProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
