import NewUser from "../components/NewUser/NewUser";
import { CollapsedNavBar, NavBar } from "../components/newNavbar/navbar";

const AddUser = () => {
  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div className="flexCenterXY mx-5 pt-4">
          <NewUser title="User"></NewUser>
        </div>
      </div>
    </div>
  );
};
export default AddUser;
