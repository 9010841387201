import "@fortawesome/fontawesome-free";
import "slick-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import jwt_decode from "jwt-decode";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import NewWorkbookItem from "../components/worksheets/NewWorkbookItem";
import WorksheetPointer from "../components/worksheets/WorksheetPointer";
import WorksheetStats from "../components/worksheets/WorksheetStats";
import useAuth from "../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../context/LoginModalContext";
import { Skeleton } from "@mui/material";

const SelectedFlashcard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [focusedWorkbook, setFocusedWorkbook] = useState(null);
  const [relatedWorkbooks, setRelatedWorkbooks] = useState(null);
  const [decodedData, setDecodedData] = useState({});
  const authRoute = usePrivateRoute();
  const navigate = useNavigate();
  const params = useParams();
  const { auth } = useAuth();
  const { showModal } = useModal();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const [zoomStyles, setZoomStyles] = useState({});

  const handleMouseMove = (e, scale, tile) => {
    const { left, top, width, height } = tile.getBoundingClientRect();
    const mouseX = e.clientX - left;
    const mouseY = e.clientY - top;
    const percentX = mouseX / width;
    const percentY = mouseY / height;

    setZoomStyles({
      transform: `scale(${scale})`,
      transformOrigin: `${percentX * 100}% ${percentY * 100}%`,
    });
  };

  const isNotPremium = () => {
    return (
      focusedWorkbook?.premium &&
      !decodedData?.premium &&
      !["admin", "superadmin", "operator"].includes(decodedData?.role)
    );
  };

  useEffect(() => {
    console.log(zoomStyles);
  }, [zoomStyles]);

  const handleMouseEnter = (scale) => {
    setZoomStyles({
      transform: `scale(${scale})`,
      transformOrigin: "center center",
    });
  };

  const handleMouseLeave = () => {
    setZoomStyles({
      transform: "scale(1)",
      transformOrigin: "center center",
    });
  };

  useEffect(() => {
    if (auth && auth.accessToken) {
      setDecodedData(jwt_decode(auth.accessToken));
    } else {
      setDecodedData({});
    }
  }, [auth]);

  useEffect(() => {
    setFocusedWorkbook(null);
    setIsLoading(true);
    authRoute
      .get(`/publicflashcards/${params.worksheetName}`)
      .then((response) => {
        setFocusedWorkbook(response.data.flashcard);
        setRelatedWorkbooks(response.data.related);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [params.worksheetName]);

  const downloadPDF = () => {
    if (focusedWorkbook.fileType == 1 || !focusedWorkbook.fileType) {
      authRoute
        .get(`flashcards/download/${focusedWorkbook._id}`, {
          responseType: "arraybuffer",
        })
        .then((data) => {
          //// Open pdf in new tab to preview
          const blob = new Blob([data.data], { type: "application/pdf" });
          const blobURL = URL.createObjectURL(blob);
          window.open(blobURL, "_blank");
          //// Directly download
          // const blob = new Blob([data.data], { type: "application/pdf" });
          // const link = document.createElement("a");
          // link.href = URL.createObjectURL(blob);
          // link.download = `${focusedWorkbook.title}.pdf`;
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
        });
    } else if (focusedWorkbook.fileType == 2) {
      authRoute
        .get(`/flashcards/link/${focusedWorkbook._id}`)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response?.data?.fileLink;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows: false,
  };

  return (
    <div
      className="d-flex flex-column user-page-content"
      style={{
        justifyContent: "start",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div className="w-100 activeWorkbook p-4">
        <div className="w-100 d-flex flex-column gap-15">
          <div className="flexRow maxContent">
            {isLoading ? (
              <Skeleton
                sx={{
                  transform: "none",
                  height: "40px",
                  width: "350px",
                }}
              />
            ) : (
              <>
                <WorksheetPointer
                  first
                  onClick={() => {
                    navigate("/", { replace: true });
                  }}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-house"
                    style={{
                      color: "#ffffff",
                    }}
                  />
                </WorksheetPointer>
                <WorksheetPointer
                  zindex={1}
                  onClick={() => {
                    navigate("/flashcards", { replace: true });
                  }}
                >
                  Flashcards
                </WorksheetPointer>
                <WorksheetPointer zindex={2} isLoading={isLoading}>
                  {focusedWorkbook?.title}
                </WorksheetPointer>
              </>
            )}
          </div>

          <div className="w-100 d-flex flex-column align-items-center">
            <div className="w-100 d-flex gap-15">
              <div className="col-md-4">
                <div className="square-image-container">
                  {isLoading ? (
                    <Skeleton
                      sx={{
                        transform: "none",
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        top: "0",
                        bottom: "0",
                      }}
                    />
                  ) : (
                    <>
                      <img
                        src={focusedWorkbook?.cover}
                        alt="cover"
                        className="tile w-100"
                        style={zoomStyles}
                        data-scale={1.3}
                        onMouseMove={(e) => handleMouseMove(e, 1.3, e.target)}
                        onMouseEnter={() => handleMouseEnter(1.3)}
                        onMouseLeave={handleMouseLeave}
                      />
                      {focusedWorkbook?.premium && (
                        <div className="worksheet-premium-container img-unclickable">
                          <FontAwesomeIcon
                            title="Premium"
                            icon="fa-solid fa-crown"
                            className="crown"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-8 d-flex flex-column gap-15">
                <div className="d-flex justify-content-between">
                  <h1
                    style={{
                      fontFamily: "Bubblegum sans",
                      fontSize: "40px",
                      position: "relative",
                    }}
                  >
                    {isLoading ? (
                      <Skeleton
                        sx={{
                          transform: "none",
                          width: "250px",
                        }}
                      />
                    ) : (
                      focusedWorkbook?.title
                    )}
                  </h1>
                  <div className="d-flex gap-15">
                    {isLoading ? (
                      <>
                        <Skeleton
                          sx={{
                            transform: "none",
                            height: "36px",
                            width: "100px",
                          }}
                        />
                        <Skeleton
                          sx={{
                            transform: "none",
                            height: "36px",
                            width: "100px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <WorksheetStats
                          icon="fa-solid fa-eye"
                          text={formatter.format(focusedWorkbook?.views ?? 0)}
                        />
                        <WorksheetStats
                          icon="fa-solid fa-download"
                          text={formatter.format(
                            focusedWorkbook?.downloads ?? 0
                          )}
                        />
                      </>
                    )}
                  </div>
                </div>
                {isLoading ? (
                  <Skeleton
                    sx={{
                      transform: "none",
                      width: "100%",
                      height: "100px",
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: focusedWorkbook?.description,
                    }}
                  ></div>
                )}
                <div className="d-flex gap-15">
                  {isLoading ? (
                    <>
                      <Skeleton
                        width={150}
                        sx={{
                          transform: "none",
                          padding: "0.375rem 0.75rem",
                        }}
                      ></Skeleton>
                      <Skeleton
                        width={150}
                        sx={{
                          transform: "none",
                          padding: "0.375rem 0.75rem",
                        }}
                      ></Skeleton>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className={`btn ${
                          isNotPremium() ? "btn-premium" : "btn-primary"
                        }`}
                        onClick={() => {
                          if (!Object.keys(auth).length) {
                            showModal("LOGIN");
                          } else if (isNotPremium()) {
                            navigate("/membership");
                          } else {
                            downloadPDF();
                          }
                        }}
                      >
                        {isNotPremium() ? (
                          <>
                            <i className="fa-solid fa-crown fa-fw"></i>
                            Go Premium
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-download fa-fw"></i>
                            Free Download
                          </>
                        )}
                      </button>
                      <button type="button" className="btn btn-outline-primary">
                        <i className="fa-regular fa-bookmark fa-fw"></i>
                        Add to collection
                      </button>
                    </>
                  )}
                </div>
                <div className="d-flex gap-15">
                  {isLoading ? (
                    <>
                      <Skeleton
                        className="pdfAttr"
                        sx={{
                          transform: "none",
                          border: "none",
                        }}
                      >
                        <p
                          style={{
                            width: "50px",
                            height: "1rem",
                          }}
                        ></p>
                      </Skeleton>
                    </>
                  ) : (
                    <>
                      <p className="pdfAttr">{focusedWorkbook.grade}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <h1
              style={{
                fontFamily: "Bubblegum sans",
                fontSize: "35px",
                alignSelf: "start",
              }}
              className="mt-4"
            >
              {isLoading ? (
                <Skeleton
                  sx={{
                    transform: "none",
                    width: "350px",
                  }}
                />
              ) : (
                "Related Learning Resources"
              )}
            </h1>
            <div
              style={{
                width: "92%",
              }}
            >
              <Slider {...settings} className="d-flex mb-3">
                {isLoading
                  ? Array.from({ length: 10 }, (_, index) => (
                      <div className="p-3">
                        <Skeleton
                          sx={{
                            transform: "none",
                            width: "100%",
                            aspectRatio: "1",
                          }}
                        />
                      </div>
                    ))
                  : relatedWorkbooks?.map((workbook) => {
                      return (
                        <NewWorkbookItem
                          name={workbook.title}
                          key={workbook._id}
                          image={workbook.cover}
                          description={workbook.description}
                          premium={workbook.premium}
                          small
                          onClick={() =>
                            navigate(
                              `/flashcards/${workbook.title.replace(
                                /[\s\/]+/g,
                                "-"
                              )}`
                            )
                          }
                        />
                      );
                    })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedFlashcard;
