import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import jwt_decode from "jwt-decode";

const RequireRoles = () => {
  const { auth } = useAuth();
  const location = useLocation();
  if (!auth?.accessToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const decoded = jwt_decode(auth.accessToken);
  console.log(decoded);

  if (["admin", "superadmin", "operator"].includes(decoded.role)) {
    if (location.pathname.includes("/admin")) {
      return <Outlet />;
    } else {
      return <Navigate to="/admin" state={{ from: location }} replace />;
    }
  } else if (["user"].includes(decoded.role)) {
    if (location.pathname.includes("/admin")) {
      return <Navigate to="/" state={{ from: location }} replace />;
    } else {
      return <Outlet />;
    }
  }

  return <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireRoles;
