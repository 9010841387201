const joinCapitals = (str) => {
  return str.split(" ").join("");
};

const splitCapitals = (str) => {
  return str.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
};

const encode_uri = (str, lower = false) => {
  return (lower ? str.toLowerCase() : str).replace(/[\s\/]+/g, "-");
};

export { joinCapitals, splitCapitals, encode_uri };
